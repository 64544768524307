import React from 'react'

function Footer() {
  return (
    <p className="credits">
      Made by <a href="https://alesh.com">Alesh</a>, 2018. <a href="https://github.com/aleshh/randoFont">GitHub</a>.
    </p>
    )
}

export default Footer