import React from 'react';

const heart = (<svg fill="none" height="24" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
  <path d="m20.84 4.61a5.5 5.5 0 0 0 -7.78 0l-1.06 1.06-1.06-1.06a5.5 5.5 0 0 0 -7.78 7.78l1.06 1.06 7.78 7.78 7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
</svg>)

// const heart = (<svg fill="none" height="24" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
//   <path d="m20.84 4.61a5.5 5.5 0 0 0 -7.78 0l-1.06 1.06-1.06-1.06a5.5 5.5 0 0 0 -7.78 7.78l1.06 1.06 7.78 7.78 7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
// </svg>)

export default heart;