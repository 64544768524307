const C = {
  FETCH_FONTS: 'FETCH_FONTS',
  SET_RANDOM_FONTS: 'SET_RANDOM_FONTS',
  SET_CURRENTLY_VIEWED_FONTS: 'SET_CURRENTLY_VIEWED_FONTS',
  SET_CATEGORY: 'SET_CATEGORY',
  UNSET_CATEGORY: 'UNSET_CATEGORY',
  SET_FONT_COUNT: 'SET_FONT_COUNT',
  ADD_FAVORITE_FONT: 'ADD_FAVORITE_FONT',
  REMOVE_FAVORITE_FONT: 'REMOVE_FAVORITE_FONT',
  TOGGLE_FAVORITE: 'TOGGLE_FAVORITE'
};

export default C;